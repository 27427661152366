import React, { createContext, useContext, useEffect, useState, ReactNode, useRef } from 'react';
import { AuthContext } from '@contexts/AuthContext';
import Logger from '@util/Logger';
import { wsApiUrl } from '@services/EndpointUtil';

const logger = Logger.make('WebSocketContext');

const MAX_RETRIES = 5;
const RETRY_DELAY = 2000; // ms

type WebSocketEventHandler = (data: any) => void;

interface WebSocketContextValue {
    socket: WebSocket | null;
    subscribe: (type: string, handler: WebSocketEventHandler) => void;
    unsubscribe: (type: string, handler: WebSocketEventHandler) => void;
    connected: boolean;
}

const WebSocketContext = createContext<WebSocketContextValue | null>(null);

interface WebSocketProviderProps {
    children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [connected, setConnected] = useState(false);
    const { getTokens, ready: authReady } = useContext(AuthContext);

    const eventHandlers = useRef<Record<string, WebSocketEventHandler[]>>({});
    const retryCountRef = useRef(0);
    const wsRef = useRef<WebSocket | null>(null);

    const tokens = getTokens();
    const accessToken = tokens?.access_token;

    // Optional: Track the previous accessToken to avoid multiple re-connect attempts
    const prevAccessTokenRef = useRef<string | null>(null);

    useEffect(() => {
        // Only proceed if auth is ready and we have a valid accessToken
        if (!authReady || !accessToken) return;

        // Check if we've already connected with this token
        if (prevAccessTokenRef.current === accessToken) {
            return; // same token as before, no need to reconnect
        }
        prevAccessTokenRef.current = accessToken;

        // If there's already a websocket instance, don't create another
        if (wsRef.current) return;

        const wsUrl = wsApiUrl('/ws/event/');
        let cleanupCalled = false;

        const connectWebSocket = () => {
            const ws = new WebSocket(wsUrl);
            wsRef.current = ws;

            ws.onopen = () => {
                if (cleanupCalled) {
                    // If cleanup ran before onopen, close immediately
                    ws.close();
                    return;
                }

                logger.info('WebSocket connection established');
                retryCountRef.current = 0;
                setConnected(true);
                setSocket(ws);

                const authPayload = JSON.stringify({
                    type: 'authenticate',
                    Authorization: `Bearer ${accessToken}`,
                });
                ws.send(authPayload);
            };

            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    logger.debug('WebSocket message received:', data);

                    if (data.type === 'auth-success') {
                        logger.info('WebSocket authenticated successfully');
                    } else if (data.type === 'auth-failure') {
                        logger.error('WebSocket authentication failed');
                        ws.close();
                    } else {
                        if (!data.type && data.event_type && data.object_type && data.object_content) {
                            data.type = 'send_notification';
                        }
                        if (data.type && eventHandlers.current[data.type]) {
                            eventHandlers.current[data.type].forEach((handler) => handler(data));
                        }
                    }
                } catch (error) {
                    logger.error('Error processing WebSocket message:', error);
                }
            };

            ws.onclose = (event) => {
                logger.info('WebSocket connection closed:', event);
                setConnected(false);
                wsRef.current = null;
                setSocket(null);

                if (!cleanupCalled && retryCountRef.current < MAX_RETRIES) {
                    retryCountRef.current += 1;
                    setTimeout(() => {
                        logger.info(`Retrying WebSocket connection (attempt ${retryCountRef.current})...`);
                        connectWebSocket();
                    }, RETRY_DELAY);
                } else if (!cleanupCalled) {
                    logger.error('Maximum WebSocket retry attempts reached');
                }
            };

            ws.onerror = (error) => {
                logger.error('WebSocket error:', error);
                setConnected(false);
            };
        };

        connectWebSocket();

        return () => {
            cleanupCalled = true;
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close();
            }
            wsRef.current = null;
            setSocket(null);
        };
    }, [authReady, accessToken]); // Removed `connected` from dependencies, rely only on token changes

    const subscribe = (type: string, handler: WebSocketEventHandler) => {
        if (!eventHandlers.current[type]) {
            eventHandlers.current[type] = [];
        }
        eventHandlers.current[type].push(handler);
    };

    const unsubscribe = (type: string, handler: WebSocketEventHandler) => {
        if (eventHandlers.current[type]) {
            eventHandlers.current[type] = eventHandlers.current[type].filter((h) => h !== handler);
        }
    };

    return (
        <WebSocketContext.Provider value={{ socket, subscribe, unsubscribe, connected }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};
